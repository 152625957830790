<template>
    <div id="parent">
        <div>
            <h3>Options</h3>
            <hr/>
            <label for="select-semester">Semester:</label>
            <b-select id="select-semester" v-model="selectedSemester" :options="semesters" @change="loadFile()"></b-select>
        </div>
        <div v-if="!selectedSemester" class="text-center text-muted">
            Begin by selecting semester on the right, or whatever options your dynamic file supports.
        </div>
        <iframe ref="file"></iframe>
    </div>
</template>
<script>
import { mapState } from 'vuex';

export default {
    props: ["type", "filename", "link"],
    data() {
        return {
            selectedSemester: (this.semester) ? this.semester.id : null,
            semesters       : []
        };
    },
    mounted() {
        this.$api.get(
            "/api/admin/get-semesters"
        ).then(({ data: { semesters } }) => {
            this.semesters = semesters.map((semester) => { return { text: semester.label, value: semester.id } });
            this.loadFile();
        });
    },
    methods: {
        loadFile() {
            if (this.selectedSemester == null) return;
            console.log(this.link);
            var urlParts = this.link.split("?");
            var searchParams = new URLSearchParams(urlParts.pop());
            /*
            var url = "";
            if (this.filename.endsWith(".pdf") && this.type == "dynamic")
                url = "/api/pdf/dynamic/" + this.filename;*/
            searchParams.set("semester_id", this.selectedSemester);
            urlParts.push(searchParams.toString());
            var url = urlParts.join("?");
            return this.$api.get(
                url,
                {
                    responseType: "blob"
                }).then(({ data }) => {
                    console.log("response:", data);
                    this.$refs.file.src = URL.createObjectURL(data);
                    /*
                    var blob      = new Blob([ response.data ]);
                    var link      = document.createElement("a");
                    link.href     = window.URL.createObjectURL(blob);
                    link.download = filename;
                    link.click();*/
                });
        }
    },
    computed: mapState(["semester"])
}
</script>
<style scoped>
    #parent {
        display: grid;
        grid-template-columns: 1fr 2fr;
    }
    #parent div {
        padding: 0px 15px;
    }
    iframe {
        border: none;
        width: 100%;
        height: 720px;
    }
</style>